import React from 'react'
import './header.css'
// import jsCert1 from '../../assets/transparent-logo-yellow.svg'
import { TypeAnimation } from 'react-type-animation'
const Header = () => {
  return (
    <div className='header' id='home'>
      <div className="header-content">
        <h1 className='gradient-text'>
          <span className='gradient__text'><h4>Hello, I'm</h4>{" "}</span>
            <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              'MERN Developer',
              1000, // wait 1s before replacing "Mice" with "Hamsters"
              'NextJS Developer',
              1000,
              'Hosting Provider',
              1000
            ]}
            wrapper="span"
            className='gradient__text'
            speed={50}
            // style={{ fontFamily: 'rageItalic' }}
            cursor={true}
            repeat={Infinity}
          />
        </h1>
        <span id="valeed">Valeed<br />Naeem</span>
        <p>An experienced developer dedicated to delivering reliable solutions tailored to your needs. Whether it's WordPress, custom coding, or front-end design, I'm here to help you achieve your goals. I prioritize listening to and understanding your requirements to create results that provide long-term benefits for your business. Let's discuss your next digital project and develop something outstanding together!</p>
        <div className='header-content_certificates'>
          certificates here ...
        </div>
        <div className='header-content_input'>
          <input type='email' placeholder='Enter your email...' />
          <button type='button'>Hire Me</button>
        </div>
      </div>

      <div className="header-image">
        <svg version="1.1" viewBox="0 0 1547 2048" width="405" height="536" id="wallvLogo" xmlns="http://www.w3.org/2000/svg">
          <path transform="translate(829,370)" d="m0 0h27l40 2 38 4 25 3 1 4-15 43-12 36-13 35-5 1-47-4h-55l-44 4-33 5-24 5-42 11-38 13-31 13-10 5-5-2-9-1h-22l-23 2-18 4-14 7-12 10-8 7-9 12-7 12-6 16-3 10-2 13-7 8-33 33-7 8-2 3h-2l-2 4-12 14-11 14-10 13-15 23-13 21-10 18-19 38-13 31-12 36-8 28-7 31-6 37-3 29-1 16v60l2 27 4 32 3 19 9 40 9 31 10 29 13 31 16 33 12 22 15 24 8 12 13 18 12 16 9 11 9 10 7 8 15 16 14 14 8 7 13 12 25 20 18 13 22 15 20 12 19 11 17 9 22 10 26 11 28 10 27 8 36 9 35 6 25 3 43 3h35l42-3 38-5 26-5 32-8 29-9 20-7 29-12 20-9 31-16 28-17 15-10 14-10 16-12 16-13 10-9 12-11 8-7 20-20 7-8 7-7 9-11 7-9 7-8 8-11 13-18 14-23 8-13 6-10 8-16 15-31 10-25 14-41 7-25 8-39 4-25 3-24 2-40v-31l-2-35-5-41-7-36-9-36-12-36-11-28-12-26-11-22-13-23-7-11-18-27-14-18-11-14-8-10-14-15-7-8-10-10v-2l-4-2-13-13-8-7-15-13 1-5 16-47 10-28 13-40 4-1 18 13 16 13 17 14 34 32 9 9 7 8 10 10 9 11 13 15 12 16 11 14 10 14 10 15 12 19 10 17 14 26 12 23 13 29 15 40 10 31 9 33 9 42 4 25 5 44 3 34v40l-4 51-5 40-5 29-8 36-10 36-9 27-15 39-12 26-11 23-12 22-14 24-8 12-14 22-18 24-10 13-11 13-9 10-7 8-15 16-23 23-8 7-9 8-20-8-10-3-16-2h-12l-16 2-16 5-19 10-12 11-9 12-9 16-6 20-1 9v29l-27 14-25 11-31 12-36 12-29 8-39 9-37 6-35 4-24 2-25 1h-26l-44-2-40-4-36-6-25-5-32-8-29-9-35-12-24-10-33-15-33-17-27-16-25-16-18-13-21-16-16-12-15-13-16-15-12-11-29-29-7-8-14-17-14-18-5-8-1-7 3-9 3-19v-39l-4-22-8-24-10-20-13-19-9-10-7-8-16-13-17-10-32-16-3-5-7-22-9-38-8-45-4-34-2-34v-66l3-39 6-44 7-35 7-29 7-26 15-43 12-30 7-15 19-38 11-20 14-23 9-14 13-18 14-19 13-16 8-10 14-17 7-8 39-39 8-7 10-9 11-9 8-7 16-12 17-13 14-10 19-12 14-9 18-10 22-12 19-10 34-15 34-13 30-10 32-9 37-8 21-4 39-5 35-3z" fill="#383433"/>
          <path transform="translate(1208,2)" d="m0 0h24l21 7 1-5 2-2h3v8l10 2v9l-4 11-6 10-5 12-4 8-5 13v5l-5 5-11 24-7 16-2 8-6 9-9 19-5 16v21l3 1v3l-4 1-1 6-5 6-10 8-10 13-7 8-4 27-4 20v17l21 8 2 2-1 32 2 9 4 15 2 6v17h4l1-9 6-13 7-20 8-26 11-24 6-14 6-13 6-16 8-18 6-10 6-7 4-4 1-11 4-10 9-7 23-8 12-7h3l-1 5-6 13-10 18-10 19-8 17-7 18-9 20-7 18-10 29-2 6 3 1-1 4-3 1v2h-2l-2 6-6 12-4 15-5 25-12 35-9 26-6 15-5 15-8 23-5 19-10 26-4 11-5 16-5 17-9 26-8 22-8 25-6 17-4 12-4 11-2 8-5 10-3 12-9 23-7 25-9 25-4 13-2 13-7 18-4 15-5 12v9l-6 10-5 12-3 13-6 15-5 12-4 9-1 2-4-2-3-7-2-9-4-16v-18l2-12 3-9 2-12 7-30 10-37 5-19 11-36 5-17 13-43 4-12 6-17 8-26 10-29 6-21 9-25 6-17 5-14 7-21 2-12h-14l-9 28-7 24-6 17-8 21-8 26-5 14-10 34-11 32-8 22-6 20-10 31-6 24-8 15-4 14-10 32-7 20-2 6-2 10-4 13-4 15-7 33-11 34-9 39-8 29-7 30-2 17-5 19-6 22-4 17-5 16-5 21-9 30-6 24-5 28-9 44 1 13 5 19 9 31 6 32 7 40 3 13 3 23v11h10l7 3 2 2-1 4-21 14-10 7-9 5-13 4-14 1-12-2-8-4h-6l-4-5-2-1-2 5-6 7-5 9h-4v-40l-3-19h-10l-7 10-7 19-9 15-6 7-7 2h-15l-7-8-5-11-3-6-11-1-2-2-2-13-3-12-1-7-1-17-1-34-2-18-4-20-5-31-12-47-6-22-3-7-8-36-4-13-4-16-9-28-6-29-3-24-6-31-11-23-6-10-8-11-7-12-3-20-3-9-2-9-2-5-7-31-4-23-13-44-13-43-4-11-2-13-6-17-2-17-4-11-10-18-6-10-5-12-9-19-11-32-7-21-4-10-6-31 1-10 6-23 4-16 4-7 11-8 14-7 3-2h12l16 1 12 5 19 7 12 6 20 6 3 2 7 2h8l10 3 7 1 1 5-2 1 1 4 2 6 3 24 3 13 6 25 4 6 8 10 7 10 6 8 7 8 8 3v2l3-2h6l2 2 1 22v18l-3 18-8 29-3 13v13l3 12 8 11 7 6 6 8 6 5 4 3-2 4-4 9-6 9v23l6 19 2 5 1 9 6 17 8 22 6 14 5 9 10 4 6 5 5 6h3l7-21 4-14 6-37 5-16 4-18 1-14 2-2 2-10 6-19 3-11 2-6 1-14 3-4 7-24 5-20 11-34 3-12 7-15 6-20 5-15 3-11 8-20 5-19 5-14 7-22 5-15 10-31 5-15 3-7v-12l4-15 4-12 4-7 6-15 9-17 11-36 6-17 6-13 7-22 4-8 3-2 7-24 4-20 7-20 6-16 18-45 8-16 5-12 11-28 18-43 8-19 16-33 13-28 14-29 6-16 5-8 11-26 7-16 6-9h4l3 1v-2l2-1z" fill="#FDCA0F"/>
          <path transform="translate(109,1330)" d="m0 0 21 2 26 5 9 2 13 5 5 5 7 3 5 6 6 4 8 9 9 12 6 7 3 14 2 3 4 17v39l-3 30-2 6-10 15-13 13-9 6-7 7-11 8-14 8-12 3-21 3-14 1h-29l-13-7-10-7-11-7-11-8-7-5-9-12-9-15-8-18-5-13-1-25 11-40 6-15 11-24 7-8 10-7 8-6 16-8 9-3 8-2z" fill="#FDCA0F"/>
          <path transform="translate(1249,1643)" d="m0 0h29l11 7 7 6 4 8 8 21 2 5v8l-3 9-5 9-6 3-2 6-20 10-7 4-9-2h-7l-6-3-11-10-8-6-7-15-1-14v-19l2-10 4-6 3-3 16-6z" fill="#FDCA0F"/>
          <path transform="translate(847,1956)" d="m0 0h16l12 3 8 4 10 9 7 5 5 9v12l-4 17-7 8-7 5-7 3-15 2h-15l-4-2-7-9-2-4-3-16-6-12-2-7v-8l8-11 10-7z" fill="#FDCA0F"/>
          <path transform="translate(1032,1192)" d="m0 0h7l11 3 9 8 6 12v32l-7 16-6 7-10 7-6 4h-12l-5-5-8-15-3-11-1-8v-19l5-12 5-9 5-6z" fill="#FDCA0F"/>
          <path transform="translate(1082,1465)" d="m0 0h13l18 4 3 3 1 4-2 15-5 10-4 4h-19l-10-5-5-4-5-8-1-7 2-7 5-5z" fill="#FDCA0F"/>
        </svg>

      </div>

    </div>

  )
}

export default Header