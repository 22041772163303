import React from 'react'
import './services.css'
const Services = () => {
  return (
    <div>
      <h3>This is the services page.</h3>
    </div>
  )
}

export default Services