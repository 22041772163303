import React from 'react'
import './cta.css'
const CTA = () => {
  return (
    <div>
      Call To Action Page
    </div>
  )
}

export default CTA