import React from 'react'
import './feature.css'
const Feature = () => {
  return (
    <div>
      Featiures Page
    </div>
  )
}

export default Feature