import React from 'react'
import './brand.css'
const Brand = () => {
  return (
    <div>
      Brand Page
    </div>
  )
}

export default Brand