import React from 'react'
import './footer.css'
const Footer = () => {
  return (
    <div>
      Footer Page
    </div>
  )
}

export default Footer